





























import { Vue, Component } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveBandeirasDeCartaoUseCase } from '@/usecases'
import { BandeirasDeCartao } from '@/models'
@Component
export default class DialogoDeEdicaoDeBandeiraDeCartao extends Vue {
	bandeira: BandeirasDeCartao | null = null
	mostra = false
	salvando = false
	saveBandeirasDeCartaoUseCase = new SaveBandeirasDeCartaoUseCase()

	bandeiraEmEdicao: BandeirasDeCartao = {
		id: '',
		nome: '',
	}

	mostrar(bandeira) {
		this.mostra = true
		this.bandeiraEmEdicao.id = bandeira.id
		this.bandeiraEmEdicao.nome = bandeira.nome
	}
	esconder() {
		this.mostra = false
	}
	async salvar() {
		try {
			this.salvando = true
			const bandeira = this.bandeiraEmEdicao
			const bandeiraSalva = this.bandeiraEmEdicao.id
				? await this.saveBandeirasDeCartaoUseCase.update(bandeira)
				: await this.saveBandeirasDeCartaoUseCase.create(bandeira)
			this.$emit('confirmado', bandeiraSalva)
			this.mostra = false
			AlertModule.setSuccess('Bandeira salva com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
